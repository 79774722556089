import React, { Component } from "react";

import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./components/Home/Index";
import Varify from "./components/Home/Varify";

import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

import AdminDashboard from "./components/Users/Admin/Dashboard/DispatchDashboard";
import AdminBookingRequest from "./components/Users/Admin/Dashboard/BookingRequest";
import AdminBookingCollection from "./components/Users/Admin/Dashboard/BookingCollection";
import AdminBookingReturn from "./components/Users/Admin/Dashboard/BookingReturn";
import AdminBookingDispatch from "./components/Users/Admin/Dashboard/BookingDispatch";

import AdminKits from "./components/Users/Admin/Kits/Kits";
import AdminSets from "./components/Users/Admin/Sets/Index";
import AdminSetsEdit from "./components/Users/Admin/Sets/EditSet";
import AdminProducts from "./components/Users/Admin/Products/Index";
import AdminCategories from "./components/Users/Admin/Kits/index";
import AdminUsers from "./components/Users/Admin/Users/Index";
import AdminHospital from "./components/Users/Admin/Hospitals/Index";
import AdminBooking from "./components/Users/Admin/Booking/Index";
import AdminBookingClose from "./components/Users/Admin/Booking/Close";
import AdminBookingCancel from "./components/Users/Admin/Booking/Cancel";
import AdminAddBooking from "./components/Users/Admin/Booking/Add";
import AdminEditBooking from "./components/Users/Admin/Booking/Edit";
import AdminDetailsBooking from "./components/Users/Admin/Booking/Details";
import AdminBookingConsignment from "./components/Users/Admin/Booking/Consignment";
import AdminAvailability from "./components/Users/Admin/Availability/Index";
import AdminSurgeon from "./components/Users/Admin/Surgeon/Index";
import AdminItemUsed from "./components/Users/Admin/Booking/ItemUsed";

import OpenBooking from "./components/Users/Reps/Booking/Index";
import ClosedBooking from "./components/Users/Reps/Booking/Close";
import ConsignmentBooking from "./components/Users/Reps/Booking/Consignment";
import RejectBooking from "./components/Users/Reps/Booking/Reject";
import CancelBooking from "./components/Users/Reps/Booking/Cancel";
import AddBooking from "./components/Users/Reps/Booking/Add";
import EditBooking from "./components/Users/Reps/Booking/Edit";
import DetailsBooking from "./components/Users/Reps/Booking/Details";
import RepHospital from "./components/Users/Reps/Hospitals/Index";
import RepSurgeon from "./components/Users/Reps/Surgeon/Index";
import RepsItemUsed from "./components/Users/Reps/Booking/ItemUsed";
import RepsAvailability from "./components/Users/Reps/Availability/Index";
import ChangePassword from "./components/Users/Reps/User/ChangePassword";
import EditProfile from "./components/Users/Reps/User/EditProfile";
import { history } from "./helpers";
import { alertActions } from "./actions";
import HolidayList from "./components/Users/Admin/Booking/holidayList";
import Operation from "./components/Users/Admin/Operation/Index";
import RepsOperation from "./components/Users/Reps/Operation/Index";
import EquipmentUsedReport from "./components/Users/Admin/Dashboard/EquipmentUsedReport";

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      console.log(location, action);
      dispatch(alertActions.clear());
    });
  }

  render() {
    //const login = localStorage.getItem("isLoggedIn");
    return (
      <div>
        <Router history={history}>
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/password/reset/:token"
              component={ResetPassword}
            />
            <Route path="/user/verify/:id/:token" component={Varify} />
            <Route exact path="/admin/dashboard" component={AdminDashboard} />
            <Route
              exact
              path="/admin/dashboard/booking-request"
              component={AdminBookingRequest}
            />
            <Route
              exact
              path="/admin/dashboard/booking-collections"
              component={AdminBookingCollection}
            />
            <Route
              exact
              path="/admin/dashboard/booking-returns"
              component={AdminBookingReturn}
            />
            <Route
              exact
              path="/admin/dashboard/booking-dispatch"
              component={AdminBookingDispatch}
            />
            <Route
              exact
              path="/admin/dashboard/equipment-used-report"
              component={EquipmentUsedReport}
            />

            <Route exact path="/admin/kits" component={AdminKits} />
            <Route exact path="/admin/sets" component={AdminSets} />
            <Route
              exact
              path="/admin/edit-sets/:id?"
              component={AdminSetsEdit}
            />
            <Route exact path="/admin/products" component={AdminProducts} />
            <Route exact path="/admin/categories" component={AdminCategories} />
            <Route exact path="/admin/users" component={AdminUsers} />
            <Route exact path="/admin/hospitals" component={AdminHospital} />
            <Route exact path="/admin/bookings" component={AdminBooking} />
            <Route
              exact
              path="/admin/bookings/close"
              component={AdminBookingClose}
            />
            <Route
              exact
              path="/admin/bookings/cancel"
              component={AdminBookingCancel}
            />
            <Route
              exact
              path="/admin/booking/add"
              component={AdminAddBooking}
            />
            <Route
              exact
              path="/admin/booking/edit/:id?"
              component={AdminEditBooking}
            />
            <Route
              exact
              path="/admin/booking/details/:id?"
              component={AdminDetailsBooking}
            />
            <Route
              exact
              path="/admin/bookings/consignment"
              component={AdminBookingConsignment}
            />
            <Route
              exact
              path="/admin/availability"
              component={AdminAvailability}
            />
            <Route exact path="/admin/surgeons" component={AdminSurgeon} />
            <Route
              exact
              path="/admin/booking/item-used/:f?/:id?"
              component={AdminItemUsed}
            />
            <Route path="/admin/holiday-list" component={HolidayList} />
            <Route path="/admin/operations" component={Operation} />

            <Route exact path="/reps/open-booking" component={OpenBooking} />
            <Route
              exact
              path="/reps/consignment-booking"
              component={ConsignmentBooking}
            />
            <Route
              exact
              path="/reps/reject-booking"
              component={RejectBooking}
            />
            <Route
              exact
              path="/reps/closed-booking"
              component={ClosedBooking}
            />
            <Route
              exact
              path="/reps/cancel-booking"
              component={CancelBooking}
            />
            <Route exact path="/reps/booking/add" component={AddBooking} />
            <Route
              exact
              path="/reps/booking/edit/:id?"
              component={EditBooking}
            />
            <Route
              exact
              path="/reps/booking/details/:id?"
              component={DetailsBooking}
            />
            <Route exact path="/reps/hospitals" component={RepHospital} />
            <Route exact path="/reps/surgeons" component={RepSurgeon} />
            <Route
              exact
              path="/reps/availability"
              component={RepsAvailability}
            />
            <Route
              exact
              path="/reps/booking/item-used/:f?/:id?"
              component={RepsItemUsed}
            />
            <Route path="/reps/change-password" component={ChangePassword} />
            <Route path="/reps/edit-profile" component={EditProfile} />
            <Route path="/reps/operations" component={RepsOperation} />
          </div>
        </Router>
      </div>
    );
  }
}

export default connect()(App);
