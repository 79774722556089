import { repsAvailabilityConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function repAvailabilitys(state = initialState, action) {
    switch (action.type) {
    case repsAvailabilityConstants.GETALLREPSAVAILBILITYS_REQUEST:
        return {
            loading: true
        };
    case repsAvailabilityConstants.GETALLREPSAVAILBILITYS_SUCCESS:
        return {
            loading: false,
            lists: action.lists.data
        };
    case repsAvailabilityConstants.GETALLREPSAVAILBILITYS_FAILURE:
        return { 
            error: action.error
        };
   
    default:
        return state;
    }
}

