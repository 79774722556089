import { availabilityConstants } from "../constants";
import { repsAvailabilityService } from "../services";

export const repsAvailabilityActions = {
    getAvailability
};

function getAvailability(search) {
    return dispatch => {
        dispatch(request());

        repsAvailabilityService.getAvailability(search)
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error))
            );
    };

   function request() { return { type: availabilityConstants.GETALLAVAILBILITYS_REQUEST }; }
      function success(lists) { return { type: availabilityConstants.GETALLAVAILBILITYS_SUCCESS, lists }; }
      function failure(error) { return { type: availabilityConstants.GETALLAVAILBILITYS_FAILURE, error }; }
}
